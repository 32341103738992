.homeCards {
    padding-top: 3%;
}

.caroselDiv {
    height: 70vh;
    width: 100vw;
    overflow: hidden!important;
}

#carosel0 {
  background: url('/images/light8.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 80vh;
  position: relative;
  text-align: right;
}

#carosel1 {
  background: url('/images/light3.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 80vh;
  position: relative;
  text-align: right;
}
#carosel2 {
  background: url('/images/light4.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 80vh;
  position: relative;
  text-align: right;
}
#carosel3 {
  background: url('/images/yard1.jpg') 50% 0 repeat-y fixed;
    background-size:cover;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 80vh;
  position: relative;
  text-align: right;
}
@media (max-width: 767px) {
    #carosel0 {
      background-attachment: scroll;
    }
    #carosel1 {
      background-attachment: scroll;
    }
    #carosel2 {
      background-attachment: scroll;
    }
    #carosel3 {
      background-attachment: scroll;
    }
}

#card1 {
  background: url('/images/light9.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-attachment: scroll;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 280px;
  position: relative;
}

#card2 {
  background: url('/images/yard3.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-attachment: scroll;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 280px;
  position: relative;
}

#card3 {
  background: url('/images/drafting4.jpg') 50% 0 repeat-y fixed;
  background-size:cover;
  background-attachment: scroll;
  background-position: center center;
  display: -webkit-box;
  display: -webkit-flex;
   display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;
  height: 280px;
  position: relative;
}