.footerText {
    text-align: center;
    color: rgb(177, 177, 177);
}

.paddedFooter {
    padding-top: 40px;
    padding-bottom: 20px;
}

.callButton {
  background: rgb(177, 177, 177);
  color: black;
  border: solid rgb(177, 177, 177);
}
.callButton:hover {
  background: #fff;
  color: black;
  border: solid rgb(177, 177, 177);
}