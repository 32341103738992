.center {
  text-align: center;
}

.right {
  text-align: right;
}

.topPad {
  padding-top: 3vh;
}